@import '~antd/dist/antd.css';
@import 'antOverrides.css';

img.logo {
    width: 168px;
    margin: 16px;
}

.site-layout-background {
    background: #fff;
}

.client-list-pane {
    background: #fff;
    padding: 20px;
}

.list-header-space {
    width: 100%;
}

.list-header-space .ant-space-item:first-child {
    width: 100%
}

pre code .hashkey {
    color: #015692
}

pre code .err {
    color: red
}

pre {
    white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
    white-space: -pre-wrap; /* Opera */
    white-space: -o-pre-wrap; /* Opera */
    white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
}

.ant-table-cell-ellipsis * {
    text-overflow: ellipsis;
    overflow: hidden;
}
