/* see https://ant.design/components/auto-complete/ */
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

/* see https://ant.design/components/table/#components-table-demo-edit-row */
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

/* see https://ant.design/components/table/#components-table-demo-drag-sorting */
tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

tr.ant-table-row:not(:hover) .show-on-hover {
  visibility: hidden;
}

.draggable-card {
  padding: 8px;
  cursor: move;
}

.draggable-horizontal .drop-over-downward {
  border-right: 2px dashed #1890ff;
}

.draggable-horizontal .drop-over-upward {
  border-left: 2px dashed #1890ff;
}

.loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

/* see https://ant.design/components/tabs/#components-tabs-demo-card-top */
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

/* allow arrows to show, see https://ant.design/components/carousel/#How-to-add-custom-arrows */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}
